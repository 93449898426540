import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import {
  ITbCard,
  ITbValuedBenefitsHero,
} from '../../shared/interfaces/contentful';
import { AuthenticationService } from '../../authentication.service';

@Component({
  selector: 'app-iah-home',
  templateUrl: './iah-home.component.html',
  styleUrls: ['./iah-home.component.scss'],
})
export class IahHomeComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean = false;

  private authSubscription!: Subscription;

  hero!: ITbValuedBenefitsHero;

  cards: ITbCard[] = [];

  constructor(
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.authSubscription = this.authService.isSsoAuthenticated$.subscribe(
      (isAuth: boolean) => {
        this.isAuthenticated = isAuth;
      },
    );

    this.activatedRoute.data.subscribe((data: any) => {
      const { fields } = data?.content || {};
      const { title } = fields;
      this.hero = fields.hero;
      this.cards = fields.cards;
      this.titleService.setTitle(`Team Benefits | ${title}`);
    });
  }

  ngOnDestroy() {
    // Always unsubscribe to prevent memory leaks
    this.authSubscription.unsubscribe();
  }
}
