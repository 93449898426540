import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAllowDateTimeOnly]'
})
export class AllowDateTimeOnlyDirective {
  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: { stopPropagation: () => void; }) {
    const initialValue = this._el.nativeElement.value;

    // eslint-disable-next-line
    this._el.nativeElement.value = initialValue.replace(/[^0-9\/]+/g, '');
    if (initialValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
