import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  apigee_sdc_base_url: 'https://test-api.woolworthsrewards.com.au',
  apigee_sdc_clientid: 'T5BQj1NfT1BlwlnqSwcWitzqs7YmTAqB',
  edrLoginUrl: 'https://www-dev.everydayrewards.co.nz/partner-link?partnerid=2009',
  googleClientId: '55775928741-umd04nq02nvb7jgcl32r7k49kfp467ua.apps.googleusercontent.com',
  enablePreview: true,
  contentful: {
    spaceId: 'gbhr96tejqwq',
    environment: 'master',
    api_string: 'CFPAT-7BWH0nDBnfCLtjUvM7O42KROjgCYqOS0o_qj1_oqtOE',
  },
};
