import { Component } from '@angular/core';

@Component({
  selector: 'app-call-to-actions',
  templateUrl: './call-to-actions.component.html',
  styleUrls: ['./call-to-actions.component.scss']
})
export class CallToActionsComponent {

}
