import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SearchResult } from './shared/interfaces/search-results';
import { ContentService } from './content.service';
import { ITbEdr, ITbPagesMainCategory, ITbValuedBenefitsPage } from './shared/interfaces/contentful';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private searchResultsSource = new BehaviorSubject<SearchResult[]>([]);

  searchResults$ = this.searchResultsSource.asObservable();

  constructor(private contentService: ContentService) {}

  performSearch(term: string): Observable<SearchResult[]> {
    return new Observable((observer) => {
      this.contentService.searchContent(term).subscribe((results) => {
        const mappedResults = results.map((item: any) => this.mapToSearchResult(item));
        this.searchResultsSource.next(mappedResults);
        observer.next(mappedResults);
        observer.complete();
      });
    });
  }

  private mapToSearchResult(item: any): SearchResult | undefined {
    let slug = '';
    const title = item.fields.title;

    switch (item.sys.contentType.sys.id) {
      case 'tbEdr':
        slug = `everyday-rewards-plus/${item.fields.slug}`;
        break;
      case 'tbPagesMainCategory':
        slug = '/';
        break;
      case 'tbCategoryPage':
        slug = `i-am-here/${item.fields.slug}`;
        break;
      case 'tbPagesMainCategoryPage':
        slug = item.fields.slug;
        break;
      case 'tbPagesSimplePage':
        slug = item.fields.slug;
        break;
      case 'tbValuedBenefitsPage':
        slug = `valued-benefits/${item.fields.slug}`;
        break;
      default:
        return undefined;
    }

    return { slug, title };
  }
}
