import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  BehaviorSubject, Observable, switchMap, timer
} from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  previewsVisibleSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  previewsVisible: Observable<boolean> = this.previewsVisibleSubject.asObservable();

  constructor(private http: HttpClient) {}

  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    const token = localStorage.getItem('team-benefits-sso-token');
    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }
    if (this.previewsEnabled()) {
      headers = headers.set('preview', 'true');
    }
    return headers;
  }

  previewsEnabled() {
    return environment.enablePreview && this.previewsVisibleSubject.value;
  }

  showPreviews() {
    this.previewsVisibleSubject.next(true);
  }

  hidePreviews() {
    this.previewsVisibleSubject.next(false);
  }

  searchContent(query: string): Observable<any> {
    const headers = this.getHeaders();

    return this.http.get(`/api/search?query=${query}`, {
      headers,
    });
  }

  getContentById(entryId: string, refreshRateInSeconds: number = 2): Observable<any> {
    const headers = this.getHeaders();

    if (this.previewsEnabled()) {
      return timer(0, refreshRateInSeconds * 1000).pipe(
        switchMap(() => this.http.get(`/api/content/${entryId}?include=3`, { headers }))
      );
    }
    return this.http.get(`/api/content/${entryId}?include=3`, { headers });
  }

  getContentBySlug(contentType: string, slug: string, refreshRateInSeconds: number = 2): Observable<any> {
    const headers = this.getHeaders();

    const url = `/api/content/${contentType}/${slug}?include=3`;

    if (this.previewsEnabled()) {
      return timer(0, refreshRateInSeconds * 1000).pipe(
        switchMap(() => this.http.get(url, { headers }))
      );
    }
    return this.http.get(url, { headers });
  }
}
